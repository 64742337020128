import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {

	appConfig = {
		//xampp local
		//"api_url": "http://vsmashapi.local/v1/api",

		//local
		//"api_url": "http://localhost:8681/v1/api",

		//production
		//"api_url": "https://vsmashgae.as.r.appspot.com/v1/api",
		//"api_url": "https://vsmash.onlineshops.my/v1/api/",
		"api_url_pure": "https://api.vsmash.com/",
		"api_url": "https://api.vsmash.com/v1/api",
		"frontend_url": "https://vsmash.com/",
		version: "0.0.67",
	};


	loadingAlert: any;

	constructor() {
		if (location.hostname.indexOf('local') > -1) {
			this.appConfig.api_url = "http://vsmashapi.local/v1/api";
			this.appConfig.api_url_pure = "http://vsmashapi.local/";
			this.appConfig.frontend_url = "http://localhost:4230/";
			//this.appConfig.api_url = "https://vsmash.onlineshops.my/v1/api";

		} else {
			if (window.location.hostname.indexOf('vsmash-admin.web.app') > -1) {
				this.appConfig.api_url_pure = "https://stagingapi.vsmash.com/";
				this.appConfig.api_url = "https://stagingapi.vsmash.com/v1/api";
				this.appConfig.frontend_url = "https://vsmashgae.web.app/";

			} else {
				this.appConfig.api_url_pure = "https://api.vsmash.com/";
				this.appConfig.api_url = "https://api.vsmash.com/v1/api";
				this.appConfig.frontend_url = "https://vsmash.com/";
			}
		}
		//console.log(this.appConfig.api_url);
	}

	showAlert(title: string, msg: string) {
		Swal.fire(title, msg, 'success');
	}

	showError(title: string, msg: string) {
		Swal.fire(title, msg, 'warning');
	}

	loadingDisplay = (display: boolean, timer: number = 1000, mytitle: string = 'Loading ') => {
		if (display) {
			let html = '<div id="myloading" style="height:130px;padding-top:40px"><div><div class="spinner-chase"><div class="chase-dot"></div><div class="chase-dot"></div><div class="chase-dot"></div><div class="chase-dot"></div><div class="chase-dot"></div><div class="chase-dot"></div></div></div></div>';

			html += "<h3>" + mytitle + "</h3>";
			this.loadingAlert = Swal.fire({
				width: 400,
				padding: 30,
				html: html,
				allowOutsideClick: false,
				timerProgressBar: true,
				showConfirmButton: false,
				timer: 8000,
			});
		} else {
			if (this.loadingAlert) {
				setTimeout(() => {
					this.loadingAlert.close();
				}, timer);
			}

		}

	}

	imgErrorHandler(event: any) {
		//console.log("errorimg",event);
		if (event.type == "error") {
			let src = event['target']['src'];
			event['target']['src'] = "./assets/images/imgNotFound.png";
			//console.log(src);

		}
	}
}
